import axios, { AxiosRequestConfig } from "axios";
import { std } from "./std";

const api = axios.create({
    baseURL:std.isDevelopment ? 'http://localhost:50001' : '/',
  // baseURL:'/',
    timeout: 10000,
    withCredentials: true,
   
});

const API = {
    key: "ab3613d87150457dac221134e4b6e148",
    seemetrix: "https://cloud.analytics.3divi.com/api/"
}
  
const axiosGet = async (url: string, params?: any, config?: AxiosRequestConfig) => {
    try {
        return  await api.get(url, { params, ...config });
    
    } catch (error) {
        throw error;
    }
};

const axiosPost = async (url: string, data?: object, config?: object) => {
  try {
    return await api.post(url, data, config);
    } catch (error) {
        throw error;
    }
};

export { api, axiosGet, axiosPost, API };

