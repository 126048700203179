
import { createTheme } from "@mui/material"
import { ptBR } from "@mui/x-data-grid"
import { ReactElement } from "react"
import { JsonColsDef } from "../../components/Grid/GridColsDef"
import { GroupText } from "../../components/GroupText/GroupTextRoot"

// aqui os objetos serão guardados em memória

export interface InterJsonColsDef {
    Title: string,
    Component?: ReactElement<any, any>,
    Height?: string
    requestUrl?: string
}
export const GeneralC = new JsonColsDef<InterJsonColsDef>({
    default: {
        Height: "250px",
        Component: undefined
    },
    items: [
        {
            Title: "OTS",
            Component: <GroupText t_1='1,698' t_2='pessoas' />,
            Height: "160px"
        },
        {
            Title: "Observadores",
            Component: <GroupText t_1='428' t_2='pessoas' />,
            Height: "160px"
        },
        {
            Title: "Taxa de conversão",
            Component: <GroupText t_1='25.2%' t_2='' />,
            Height: "160px"
        },
        {
            Title: "Tempo de permanência",
            Component: <GroupText t_1='8,538' t_2='segundos' />,
            Height: "160px"
        },
        {
            Title: "Tempo de atenção",
            Component: <GroupText t_1='2,444' t_2='segundos' />,
            Height: "160px"
        },
        {
            Title: "Taxa de atração",
            Component: <GroupText t_1='28.6%' t_2='' />,
            Height: "160px"
        },
        {
            Title: "Divisão OTS-Observadores",
        },
        {
            Title: "OTS-Observadores durante a semana média",
        },
        {
            Title: "OTS-Observadores durante o dia médio"
        },
        {
            Title: "Divisão Permanência-Atenção"
        },
        {
            Title: "Permanência-Atenção durante a semana média"
        },
        {
            Title: "Permanência-Atenção durante o dia médio"
        }]
})

export const DemographicsC = new JsonColsDef<InterJsonColsDef>({
    default: {
        Height: "300px",
        Component: undefined,
    },
    items: [
        {
            Title: "Masculino",
            Height: "160px",
        },
        {
            Title: "Feminino",
            Height: "160px",
        },
        {
            Title: "Grupo demográfico principal",
            Height: "160px",
        },
        {
            Title: "Atenção média masculina",
            Height: "160px",
        },
        {
            Title: "Atenção média feminina",
            Height: "160px",
        },
        {
            Title: "Maior tempo de atenção",
            Height: "160px",
        },
        {
            Title: "Divisão por Gênero-Idade",
            requestUrl: ""
        },
        {
            Title: "Divisão por Gênero",
        },
        {
            Title: "Divisão por Idade",
        },
        {
            Title: "Total de visualizações durante a semana média",
            Height: "390px",
        },
        {
            Title: "Total de visualizações durante o dia médio",
            Height: "390px",
        },
        {
            Title: "Atenção total durante a semana média",
            Height: "390px",
        },
        {
            Title: "Atenção total durante o dia médio",
            Height: "390px",
        },
        {
            Title: "Divisão de emoções",
        },
        {
            Title: "Emoções por gênero",
        },
        {
            Title: "Emoções por idade",
        }
    ]
})

export const MediaC = new JsonColsDef<InterJsonColsDef>({
    default: {
        Height: "160px",
        Component: undefined,
    },
    items: [
        {
            Title: "Número total de OTS",
        },
        {
            Title: "Número total de observadores"
        },
        {
            Title: "Conteúdo principal para atenção"
        },
        {
            Title: "Conteúdo principal para felicidade"
        },
        {
            Title: "Tempo médio de permanência"
        },
        {
            Title: "Atenção média"
        },
        {
            Height: "auto",
            Title: ""
        },
    ]
})

export const DevicesC = new JsonColsDef<InterJsonColsDef>({
    default: {
        Height: "auto",
        Component: undefined,
    },
    items: [
        {
            Title: "",
        }
    ]
})

export const OverTimeC = new JsonColsDef<InterJsonColsDef>({
    default: {
        Height: "560px",
        Component: undefined,
    },
    items: [
        {
            Title: ""
        },
    ]
})


export const theme = createTheme(
    {
        palette: {
            mode: 'light',
            primary: { main: '#000' },
        },
    },
    ptBR,
);

export interface PropsSection {
    makeAjax: boolean;
    devices?: any
    devices_parms_url: string
}

type stringOrNumber = string | number;

export interface IEmotions {
    begin: stringOrNumber;
    data: IGenericsData
    end: stringOrNumber;
    levels: string[];
    ok: stringOrNumber;
    path: stringOrNumber;
    user_id: stringOrNumber;
}

export interface IGenericsData {
    n: string;
    o: IGenericsData[];
    ots: number;
    otsd: number;
    t: stringOrNumber;
    v: number;
    vd: number;
  }
  
  export const parmsDuplicate = <T extends { [key: string]: any }>(arr: T[],url_parm: string ,field: string, url_init=""): string => {
    arr.forEach((value, idx) => {
        if (idx > 0) {
            url_init += "&";
        }
        url_init += url_parm + "=" + value[field];
    });
    return url_init;
}
