import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import styled from "styled-components"
import { GroupText } from "../../components/GroupText/GroupTextRoot"
import WindowComponent from "../../components/WindowComponent/WindowComponent"
import useFetchData from "../../components/useFetchData"
import spinner from '../../images/spinner.gif'
import { dev, std } from "../../std"
import { GeneralC, IGenericsData, PropsSection } from "./Sections"

interface GraphicProps<T> {
  c1: string;
  c2: string;
  name1: string;
  name2: string;
  dataKey1?: string;
  dataKey2?: string;
  XAxis1?: string;
  data: T[];
}

const Graphic = <T,>({ c1, c2, data, name1, name2, dataKey1 = 'd', dataKey2 = 'p', XAxis1 = 'name' }: GraphicProps<T>) => {
  return (
    <ResponsiveContainer>
      <BarChart width={300} height={250} data={data} style={{ marginTop: "10px" }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={XAxis1} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey={dataKey1} fill={c1} name={name1} />
        <Bar dataKey={dataKey2} fill={c2} name={name2} />
      </BarChart>
    </ResponsiveContainer>


  )
}


export const GeneralSection = ({ makeAjax, devices_parms_url }: PropsSection) => {


  dev.log("GeneralSection")


  const RequestPromise1 = useFetchData<IDates>("json/datesDD.json?" + devices_parms_url, 'get', { dt_format: "DD", tzo: "-3" }, makeAjax);
  // const RequestPromise2 = useFetchData("json/datesYYYY.json", 'get', undefined, makeAjax);
  const RequestPromise3 = useFetchData<IDates>("json/datesHH.json?" + devices_parms_url, 'get', { dt_format: "HH", tzo: "-3" }, makeAjax);
  const loading = RequestPromise1.loading && RequestPromise3.loading;
  const error = RequestPromise1.error && RequestPromise3.error;
  const dados = RequestPromise1.data && RequestPromise3.data;

  //std.formatNumber
  if (dados) {
    var ots_n = RequestPromise1.data?.data?.ots || 0
    var v_n = RequestPromise1.data?.data?.v || 0
    var ots = ots_n
    var v = v_n
    var cr = std.calcPercent(v_n, ots_n)
    var otsd_n = RequestPromise1.data?.data?.otsd || 0
    var vd_n = RequestPromise1.data?.data?.vd || 0
    var otsd = otsd_n
    var vd = vd_n
    var ar = std.calcPercent(vd_n, otsd_n)

    if (RequestPromise1.data?.data?.o && RequestPromise3.data?.data.o) {
      GeneralC.items[0].Component = <GroupText t_1={ots} t_2='persons' />
      GeneralC.items[1].Component = <GroupText t_1={v} t_2='persons' />
      GeneralC.items[2].Component = <GroupText t_1={<>{cr}</>} t_2='' />
      GeneralC.items[3].Component = <GroupText t_1={<>{std.msToM(otsd)}</>} t_2='' />
      GeneralC.items[4].Component = <GroupText t_1={std.msToM(vd)} t_2='' />
      GeneralC.items[5].Component = <GroupText t_1={ar} t_2='' />
      GeneralC.items[6].Component = <Graphic c1="rgb(37,78,123)" c2="rgb(51,122,183)" data={[{ d: ots, p: v }]} name1="OTS" name2="Watchers" />
      GeneralC.items[7].Component = <Graphic<IGenericsData> c1="rgb(37,78,123)" c2="rgb(51,122,183)" data={RequestPromise1.data.data.o} name1="OTS" name2="Watchers" dataKey1="ots" dataKey2="v" />
      GeneralC.items[8].Component = <Graphic<IGenericsData> c1="rgb(37,78,123)" c2="rgb(51,122,183)" data={RequestPromise3.data.data.o} name1="OTS" name2="Watchers" dataKey1="ots" dataKey2="v" />
      GeneralC.items[9].Component = <Graphic c1="rgb(45,107,34)" c2="rgb(138,180,70)" data={[{ d: otsd, p: vd }]} name1="Dwell" name2="Attention" />
      GeneralC.items[10].Component = <Graphic<IGenericsData> c1="rgb(45,107,34)" c2="rgb(138,180,70)" data={RequestPromise1.data.data.o} name1="Dwell" name2="Attention" dataKey1="otsd" dataKey2="vd" />
      GeneralC.items[11].Component = <Graphic<IGenericsData> c1="rgb(45,107,34)" c2="rgb(138,180,70)" data={RequestPromise3.data.data.o} name1="Dwell" name2="Attention" dataKey1="otsd" dataKey2="vd" />
    }

  }



  return (

    <GeneralSectionStyled>
      <div className='c'>
        {GeneralC.items.map((data, idx) => {

          return (
            <div className={"c_h _s_" + idx} key={"general_" + idx}>
              <WindowComponent.Root  >
                <WindowComponent.Header>
                  <WindowComponent.Title >
                    {data.Title}
                  </WindowComponent.Title>
                </WindowComponent.Header>
                <WindowComponent.Content height={data.Height}>
                  {loading && <img className="c_img_loading" src={spinner} alt="" />}
                  {loading && <img className="c_img_loading" src={spinner} alt="" />}
                  {dados && data.Component}
                </WindowComponent.Content>

              </WindowComponent.Root>
            </div>
          )
        }
        )}
      </div>
    </GeneralSectionStyled>
  )
}

const GeneralSectionStyled = styled.div`
> .c{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 25px;

  >.c_h{
    width: 100%;
  }
  @media (min-width: 1100px) {
    ._s_0, 
    ._s_1, 
    ._s_2, 
    ._s_3,
    ._s_4,
    ._s_5{
      width: calc(16.66% - 31px);
    }
    
    ._s_6,
    ._s_7,
    ._s_8,
    ._s_9,
    ._s_10,
    ._s_11{
      width:calc(33.33% - 25px);

    }
  }

  @media (min-width: 700px) and (max-width: 1099px)  {
    ._s_0, 
    ._s_1, 
    ._s_2, 
    ._s_3,
    ._s_4,
    ._s_5{
      width: calc(50% - 12.5px);
     
    }
  }
}
  
`

interface IDates {
  begin: string;
  data: IGenericsData;
  dt_format: string;
  end: string;
  ok: number;
  path: string;
  user_id: number;
}

