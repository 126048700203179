import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AuthenticatedRoute, checkAuthenticatedSession, isAuthenticated } from './components/Authentication';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import { dev } from './std';



const App: React.FC = () => {
  dev.log(isAuthenticated());
  dev.log(checkAuthenticatedSession());

  return (
    <Router >
      <Routes>
        <Route path="/" element={<Login />} />;
        <Route path="/Home" element={
          <AuthenticatedRoute
            component={(parm) =>
              <Home Clients={parm.data} />
            }
          />} />

        <Route path="/Home/:section" element={
          <AuthenticatedRoute
            component={(parm) =>
              <Home Clients={parm.data} />
            }
          />} />
        
        <Route path="*" element={<Navigate to="/" />} />
        {/*
        <Route path="/Home2" element={<Home />} />;
        <Route path="/Login2" element={<Login />} />;*/}
      </Routes>
    </Router>

  );
};

export default App;


