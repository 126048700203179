import { ReactNode } from "react"
import { FaFemale, FaMale } from "react-icons/fa"
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import styled from "styled-components"
import { GroupText } from "../../components/GroupText/GroupTextRoot"
import WindowComponent from "../../components/WindowComponent/WindowComponent"
import useFetchData from "../../components/useFetchData"
import spinner from '../../images/spinner.gif'
import { dev, std } from "../../std"
import { CustomLegend, IGenderAge } from "./OverTimeSection"
import { DemographicsC, IEmotions, IGenericsData, PropsSection } from "./Sections"



const DemographicsSection = ({ makeAjax, devices_parms_url }: PropsSection) => {

  dev.log("DemographicsSection")
  const { loading: loading1, error: error1, data: data1 } = useFetchData<IEmotions>("json/emotions_2.json?" + devices_parms_url, 'get', { dt_format: "YYYY-MM-DD HH" ,tzo: "-3" }, makeAjax);
  // const { loading: loading2, error: error2, data: data2 } = useFetchData<IAges>("json/agesYYYYMMDD.json", 'get', undefined, makeAjax);
  const { loading: loading3, error: error3, data: data3 } = useFetchData<IAges>("json/agesHH.json?" + devices_parms_url, 'get', { dt_format: "HH" , tzo: "-3" }, makeAjax);
  const { loading: loading4, error: error4, data: data4 } = useFetchData<IAges>("json/agesYYYYMMDDHH.json?" + devices_parms_url, 'get', { dt_format: "YYYY-MM-DD HH", tzo: "-3"  }, makeAjax);
  const { loading: loading5, error: error5, data: data5 } = useFetchData<IAges>("json/agesDD.json?" + devices_parms_url, 'get', { dt_format: "DD", tzo: "-3"  }, makeAjax);
  const loading = loading1 && loading3 && loading4 && loading5;
  const error = error1 && error3 && error4 && error5;
  const dados = data1 && data3 && data4 && data5;

  if (dados) ensureData(data1?.data, data3?.data, data4?.data, data5?.data);

  return (
    <DemographicsStyled>
      <div className='c'>
        {DemographicsC.items.map((data, idx) =>
          <div className={"c_h _s_" + idx} key={"demographics_" + idx}>
            <WindowComponent.Root >
              <WindowComponent.Header>
                <WindowComponent.Title>
                  {data.Title}
                </WindowComponent.Title  >
              </WindowComponent.Header>
              <WindowComponent.Content height={data.Height}>
                {loading && <img className="c_img_loading" src={spinner} alt="" />}
                {error && <img className="c_img_loading" src={spinner} alt="" />}
                {dados && data.Component}
              </WindowComponent.Content>


            </WindowComponent.Root>
          </div>
        )}
      </div>
    </DemographicsStyled>
  );
}

export default DemographicsSection;


interface GraphicProps<T> {
  XAxis1?: "name" | "date";
  data: T[];
  children?: ReactNode;
}

export const GraphicSections = <T,>({ data, XAxis1 = 'name', children }: GraphicProps<T>) => {

  return (
    <ResponsiveContainer>
      <BarChart width={300} height={250} data={data} style={{ marginTop: "10px" }} >
        <CartesianGrid strokeDasharray="0 1" />
        <XAxis dataKey={XAxis1} />
        <YAxis />
        <Tooltip />
        {children}
      </BarChart>
    </ResponsiveContainer>
  );
  //  <Bar dataKey={} fill={c1} name={} />
}

interface IEmotionDataAge {
  kid: IEmotionData | number;
  young: IEmotionData | number;
  adult: IEmotionData | number;
  old: IEmotionData | number;
}

interface IEmotionDataGender {
  male: IEmotionData | number
  female: IEmotionData | number
}






interface IEmotionData {
  name: string;
  neutral: number;
  happy: number;
  angry: number;
  surprised: number;
}
type Age = 'kid' | 'young' | 'adult' | 'old'
type Gender = 'male' | 'female'



class EmotionDataAge implements IEmotionDataAge {
  kid: EmotionData = new EmotionData();
  young: EmotionData = new EmotionData();
  adult: EmotionData = new EmotionData();
  old: EmotionData = new EmotionData();
}

class EmotionDataGender implements IEmotionDataGender {
  male: EmotionData = new EmotionData();
  female: EmotionData = new EmotionData();
}

class EmotionData implements IEmotionData {
  angry: number;
  happy: number;
  neutral: number;
  surprised: number;
  name: string;

  constructor() {
    this.angry = 0;
    this.happy = 0;
    this.neutral = 0;
    this.surprised = 0;
    this.name = '';
  }
}

const ageAlias = {
  kid: "Criança",
  young: "Jovem",
  adult: "Adulto",
  old: "Senhor"
}

interface ITopDemographicGroup {
  name: string
  value: number
}

interface IProcessData {
  emotionDataByAge: IEmotionDataAge[];
  emotionDataByGen: IEmotionDataGender[];
  emotionData: IEmotionData;
  attentionEverageData: IGenderAge[];
  totalViewsEverageData: IGenderAge[];
  GenderAgeSplit: IGenderAge;
  GenderSplit: IEmotionDataGender;
  AgeSplit: IEmotionDataAge;
  attentionEverageWeek: IDayWeekData[];
  totalViewsEverageWeek: IDayWeekData[];
  topDemographicGroup: ITopDemographicGroup
  averageGenderAge: IAverageGenderAge
}

const processData = (json: IGenericsData, json2: IGenericsData, json3: IGenericsData, json4: IGenericsData): IProcessData => {
  const emotionDataByAge = new EmotionDataAge()
  const emotionDataByGen = new EmotionDataGender();
  const emotionData = new EmotionData();

  const processNode = (node: IGenericsData, gender: Gender, age: Age) => {
    if (node.t === 'emotion') {
      if (node.n === 'angry' || node.n === 'happy' || node.n === 'neutral' || node.n === 'surprised') {
        const emotion = node.n;
        emotionDataByGen[gender][emotion] += node.v;
        emotionDataByGen[gender].name = gender === "male" ? "Masculino" : "Feminino";
        if (emotionDataByAge[age]) {
          emotionDataByAge[age].name = ageAlias[age] ?? "";
          emotionDataByAge[age][emotion] += node.v;
        }
      }
    }
    if (node.o && node.o.length > 0) {
      node.o.forEach(child => processNode(child, gender, age));
    }
  };

  json.o.forEach(genderNode => {
    const gender = genderNode.n as Gender;
    genderNode.o.forEach(ageNode => {
      const age = ageNode.n as Age;
      ageNode.o.forEach(emotionNode => {
        processNode(emotionNode, gender, age);
      });
    });
  });

  emotionData.angry = emotionDataByGen.male.angry + emotionDataByGen.female.angry
  emotionData.happy = emotionDataByGen.male.happy + emotionDataByGen.female.happy
  emotionData.neutral = emotionDataByGen.male.neutral + emotionDataByGen.female.neutral
  emotionData.surprised = emotionDataByGen.male.surprised + emotionDataByGen.female.surprised

  const DayData = new CDayHoursData()
  DayData.ensureGenderAge(json2)
  const attentionEverageData = DayData.getCurrentHourData()


  const DayData2 = new CDayHoursData()
  DayData2.ensureGenderAge(json2, "v")
  const totalViewsEverageData = DayData2.getCurrentHourData()


  const { values: GenderAgeSplit, top: topDemographicGroup, averageGenderAge } = DayData2.ensureValues(json3, "v")
  const GenderSplit = {
    male: GenderAgeSplit.kid_male + GenderAgeSplit.young_male + GenderAgeSplit.adult_male + GenderAgeSplit.old_male,
    female: GenderAgeSplit.kid_female + GenderAgeSplit.young_female + GenderAgeSplit.adult_female + GenderAgeSplit.old_female
  }

  const AgeSplit = {
    kid: GenderAgeSplit.kid_male + GenderAgeSplit.kid_female,
    young: GenderAgeSplit.young_male + GenderAgeSplit.young_female,
    adult: GenderAgeSplit.adult_male + GenderAgeSplit.adult_female,
    old: GenderAgeSplit.old_male + GenderAgeSplit.old_female,

  }

  const WeekData = new CDayWeekData()
  WeekData.ensureGenderAge(json4)
  const attentionEverageWeek = WeekData.getCurrentWeekData();

  const WeekData2 = new CDayWeekData()
  WeekData2.ensureGenderAge(json4, "v")
  const totalViewsEverageWeek = WeekData2.getCurrentWeekData();
  // const averageGenderAge = { name: "", value: 0 }

  return {
    emotionDataByAge: Object.values(emotionDataByAge),
    emotionDataByGen: Object.values(emotionDataByGen),
    emotionData: emotionData,
    attentionEverageData: attentionEverageData,
    totalViewsEverageData: totalViewsEverageData,
    GenderAgeSplit: GenderAgeSplit,
    GenderSplit: GenderSplit,
    AgeSplit: AgeSplit,
    totalViewsEverageWeek: Object.values(totalViewsEverageWeek),
    attentionEverageWeek: Object.values(attentionEverageWeek),
    topDemographicGroup: topDemographicGroup,
    averageGenderAge: averageGenderAge
  };
};


function ensureData(data_1: IGenericsData, data_2: IGenericsData, data_3: IGenericsData, data_4: IGenericsData) {
  if (data_1 && data_2 && data_3) {
    const totalOts = data_1.v || 0;
    const FemaleData = data_1.o[1];
    const MaleData = data_1.o[0];

    const EmotionData = processData(data_1, data_2, data_3, data_4)

    DemographicsC.items[0].Component =
      <GroupText t_1={std.calcPercent(FemaleData.v || 0, totalOts)} t_2={""} />


    DemographicsC.items[1].Component =
      <GroupText t_1={std.calcPercent(MaleData.v || 0, totalOts)} t_2={""} />


    DemographicsC.items[2].Component =
      <GroupText t_1={AliasGender[EmotionData.topDemographicGroup.name]}
        t_2={std.calcPercent(EmotionData.topDemographicGroup.value || 0, data_3.v)} />

    DemographicsC.items[3].Component =
      <GroupText t_1={<>
        {(data_3.o[1].vd / data_3.o[1].v).formatToSeconds()}</>}
        t_2={"segundos"} />


    DemographicsC.items[4].Component =
      <GroupText t_1={(data_3.o[0].vd / data_3.o[0].v).formatToSeconds()}
        t_2={"segundos"} />


    DemographicsC.items[5].Component =
      <GroupText t_1={AliasGender[EmotionData.averageGenderAge.name]}
        t_2={EmotionData.averageGenderAge.value.formatToSeconds() + " segundos"} />


    DemographicsC.items[6].Component =
      <GraphicSections<IGenderAge> data={[EmotionData.GenderAgeSplit]}>
        <Legend content={<CustomLegend />} />
        {BarsG2.map(ar => <Bar dataKey={ar[0]} fill={ar[1]} name={ar[2]} />)}
      </GraphicSections>

    DemographicsC.items[7].Component =
      <GraphicSections<IEmotionDataGender> data={[EmotionData.GenderSplit]}>
        <Legend content={<CustomLegend />} />
        {BarsG3.map(ar => <Bar dataKey={ar[0]} fill={ar[1]} name={ar[2]} />)}
      </GraphicSections>

    DemographicsC.items[8].Component =
      <GraphicSections<IEmotionDataAge> data={[EmotionData.AgeSplit]}>
        <Legend content={<CustomLegend />} />
        {BarsG4.map(ar => <Bar dataKey={ar[0]} fill={ar[1]} name={ar[2]} />)}
      </GraphicSections>

    DemographicsC.items[9].Component =
      <GraphicSections<IDayWeekData> data={EmotionData.totalViewsEverageWeek} XAxis1="date">
        <Legend content={<CustomLegend />} />
        {BarsG2.map((ar) => <Bar dataKey={ar[0]} fill={ar[1]} name={ar[2]} stackId={ar[3]} />)}
      </GraphicSections>

    DemographicsC.items[10].Component =
      <GraphicSections<IGenderAge> data={EmotionData.totalViewsEverageData} XAxis1="date">
        <Legend content={<CustomLegend />} />
        {BarsG2.map((ar) => <Bar dataKey={ar[0]} fill={ar[1]} name={ar[2]} stackId={ar[3]} />)}
      </GraphicSections>

    DemographicsC.items[11].Component =
      <GraphicSections<IDayWeekData> data={EmotionData.attentionEverageWeek} XAxis1="date">
        <Legend content={<CustomLegend />} />
        {BarsG2.map((ar) => <Bar dataKey={ar[0]} fill={ar[1]} name={ar[2]} stackId={ar[3]} />)}
      </GraphicSections>

    DemographicsC.items[12].Component =
      <GraphicSections<IGenderAge> data={EmotionData.attentionEverageData} XAxis1="date">
        <Legend content={<CustomLegend />} />
        {BarsG2.map((ar) => <Bar dataKey={ar[0]} fill={ar[1]} name={ar[2]} stackId={ar[3]} />)}
      </GraphicSections>

    DemographicsC.items[13].Component =
      <GraphicSections<IEmotionData> data={[EmotionData.emotionData]}>
        <Legend content={<CustomLegend />} />
        {BarsG1.map(ar => <Bar dataKey={ar[0]} fill={ar[1]} name={ar[2]} />)}
      </GraphicSections>

    DemographicsC.items[14].Component =
      <GraphicSections<IEmotionDataGender> data={EmotionData.emotionDataByGen} >
        <Legend content={<CustomLegend />} />
        {BarsG1.map(ar => <Bar dataKey={ar[0]} fill={ar[1]} name={ar[2]} />)}
      </GraphicSections>

    DemographicsC.items[15].Component =
      <GraphicSections<IEmotionDataAge> data={EmotionData.emotionDataByAge}>
        <Legend content={<CustomLegend />} />
        {BarsG1.map(ar => <Bar dataKey={ar[0]} fill={ar[1]} name={ar[2]} />)}
      </GraphicSections>
  }
}

const BarsG1 = [
  ["neutral", "rgb(174,214,232)", "Neutro"],
  ["happy", "rgb(180,220,115)", "Feliz"],
  ["angry", "rgb(213,111,80)", "Bravo"],
  ["surprised", "rgb(235,131,159)", "Surpreso"]
];

export const BarsG2 = [
  ["kid_male", "rgb(216,243,255)", "Criança Masculino", "a"],
  ["young_male", "rgb(142,192,228)", "Jovem Masculino", "a"],
  ["adult_male", "rgb(67,121,182)", "Adulto Masculino", "a"],
  ["old_male", "rgb(46,77,122)", "Idoso Masculino", "a"],
  ["kid_female", "rgb(253,224,238)", "Criança Feminino", "a"],
  ["young_female", "rgb(226,132,150)", "Jovem Feminino", "a"],
  ["adult_female", "rgb(198,29,46)", "Adulto Feminino", "a"],
  ["old_female", "rgb(118,13,16)", "Idoso Feminino", "a"]
];

const BarsG3 = [
  ["male", "rgb(142,192,228)", "Homem"],
  ["female", "rgb(226,132,150)", "Mulher"],
];

const BarsG4 = [
  ["kid", "rgb(232, 249, 156)", "Criança"],
  ["young", "rgb(180, 211, 101)", "Jovem"],
  ["adult", "rgb(123, 164, 57)", "Adulto"],
  ["senior", "rgb(45, 107, 34)", "Senhor"],
];

export class CGenderAge implements IGenderAge {
  date?: string;
  kid_male: number;
  young_male: number;
  adult_male: number;
  old_male: number;
  kid_female: number;
  young_female: number;
  adult_female: number;
  old_female: number;

  constructor(data: IGenderAge) {
    this.date = data.date;
    this.kid_male = data.kid_male;
    this.young_male = data.young_male;
    this.adult_male = data.adult_male;
    this.old_male = data.old_male;
    this.kid_female = data.kid_female;
    this.young_female = data.young_female;
    this.adult_female = data.adult_female;
    this.old_female = data.old_female;
  }
}

interface IAverageGenderAge {
  name: string;
  value: number;
}

interface IEnsureValues {
  values: CGenderAge
  top: ITopDemographicGroup
  averageGenderAge: IAverageGenderAge
}

class CDayData {
  data: IGenderAge[] = [];
  formatHour(hour: number): string {
    return `${hour < 10 ? '0' : ''}${hour}:00`;
  }

  addHourData(hour: number, hourData: IGenderAge): void {
    if (this.data[hour]) {
      this.data[hour] = new CGenderAge({ date: this.formatHour(hour), ...hourData });
    } else {
      throw new Error(`Invalid hour: ${hour}. Please use format "00" to "23".`);
    }
  }

  getHourData(hour: number): IGenderAge | undefined {
    return this.data[hour];
  }
  getCurrentHourData(): IGenderAge[] {
    return this.data;
  }
  getAllHours(): string[] {
    return Object.keys(this.data);
  }
  ensureValues(no: IGenericsData, field: "vd" | "v"): IEnsureValues {

    var values: CGenderAge = new CGenderAge({
      kid_male: 0,
      young_male: 0,
      adult_male: 0,
      old_male: 0,
      kid_female: 0,
      young_female: 0,
      adult_female: 0,
      old_female: 0
    }
    )

    var top = { name: "", value: 0 }
    var averageGenderAge = { name: "", value: 0 }

    no.o.forEach((no) => {
      if (no.n !== undefined) {

        if (no.o.length > 0) {
          no.o.forEach((no2) => {
            if (no2.n !== undefined) {
              var str = no2.n + "_" + no.n
              if (str in values) {
                (values as unknown as Record<string, number>)[str] = no2[field];// Verificação de chave válida
                if (no2[field] > top.value) {
                  top.name = str
                  top.value = no2[field];
                }
                var average = no2.vd / no2.v
                if (average > averageGenderAge.value) {
                  averageGenderAge.name = str
                  averageGenderAge.value = average;
                }

              }
              // values[str] = no2[field];
            }

          })
        }
      }
    })
    return {
      values: values,
      top: top,
      averageGenderAge: averageGenderAge
    };
  }
  ensureGenderAge(data: IGenericsData, field: "vd" | "v" = 'vd') {

    data.o.forEach(no => {
      const { values, top } = this.ensureValues(no, field)



      this.addHourData(parseInt(no.n), new CGenderAge({
        date: no.n + ":00",
        kid_male: values.kid_male,
        young_male: values.young_male,
        adult_male: values.adult_male,
        old_male: values.old_male,
        kid_female: values.kid_female,
        young_female: values.young_female,
        adult_female: values.adult_female,
        old_female: values.old_female
      }))
    })
  }
}

class CDayHoursData extends CDayData {
  override data: IGenderAge[] = [];

  // Definindo CGenderAgeDefault como uma propriedade estática
  static CGenderAgeDefault = {
    kid_male: 0,
    young_male: 0,
    adult_male: 0,
    old_male: 0,
    kid_female: 0,
    young_female: 0,
    adult_female: 0,
    old_female: 0
  };

  constructor() {
    super();
    for (let hour = 0; hour < 24; hour++) {
      this.data[hour] = new CGenderAge({
        date: this.formatHour(hour),
        ...CDayHoursData.CGenderAgeDefault
      });
    }
  }


}
export interface IDayWeekData {
  sunday: IGenderAge;
  monday: IGenderAge;
  tuesday: IGenderAge;
  wednesday: IGenderAge;
  thursday: IGenderAge;
  friday: IGenderAge;
  saturday: IGenderAge;
}

const daysOfWeekTranslation = {
  sunday: "Domingo",
  monday: "Segunda-feira",
  tuesday: "Terça-feira",
  wednesday: "Quarta-feira",
  thursday: "Quinta-feira",
  friday: "Sexta-feira",
  saturday: "Sábado",
};


export class CDayWeekData extends CDayData {
  dataWeek: IDayWeekData;
  constructor() {
    super();
    this.dataWeek = {
      sunday: new CGenderAge({ date: "Domingo", ...CDayHoursData.CGenderAgeDefault }),
      monday: new CGenderAge({ date: "Segunda-feira", ...CDayHoursData.CGenderAgeDefault }),
      tuesday: new CGenderAge({ date: "Terça-feira", ...CDayHoursData.CGenderAgeDefault }),
      wednesday: new CGenderAge({ date: "Quarta-feira", ...CDayHoursData.CGenderAgeDefault }),
      thursday: new CGenderAge({ date: "Quinta-feira", ...CDayHoursData.CGenderAgeDefault }),
      friday: new CGenderAge({ date: "Sexta-feira", ...CDayHoursData.CGenderAgeDefault }),
      saturday: new CGenderAge({ date: "Sábado", ...CDayHoursData.CGenderAgeDefault }),
    };
  }

  getCurrentWeekData(): IDayWeekData {
    return this.dataWeek;
  }

  addWeekData(week: keyof IDayWeekData, weekData: IGenderAge): void {

    if (this.dataWeek[week]) {
      this.dataWeek[week] = new CGenderAge({ date: daysOfWeekTranslation[week], ...weekData });
    } else {
      throw new Error(`Invalid week: ${week}. `);
    }
  }

  static fieldsWeekData: { [key: number]: keyof IDayWeekData } = {
    1: "sunday",
    2: "monday",
    3: "tuesday",
    4: "wednesday",
    5: "thursday",
    6: "friday",
    7: "saturday"
  };


  override ensureGenderAge(data: IGenericsData, field: "vd" | "v" = 'vd') {

    data.o.forEach((no, idx) => {
      const { values, top } = this.ensureValues(no, field)
      const week = CDayWeekData.fieldsWeekData[parseInt(no.n)];
      if (Object.keys(this.dataWeek).includes(week)) {
        //  const day = no.n as keyof IDayWeekData;
        this.addWeekData(week, {
          date: daysOfWeekTranslation[week],
          kid_male: values.kid_male,
          young_male: values.young_male,
          adult_male: values.adult_male,
          old_male: values.old_male,
          kid_female: values.kid_female,
          young_female: values.young_female,
          adult_female: values.adult_female,
          old_female: values.old_female
        });
      }


    })
  }
}


export interface IAges {
  begin: string;
  data: IGenericsData;
  dt_format: string;
  end: string;
  levels: string[];
  ok: number;
  path: string;
  user_id: number;
}

/*
interface IEmotionsSplit {
  name: string;
  neutral: number;
  surprise: number;
  happy: number;
  angry: number;
}*/


const AliasGender: Record<string, ReactNode> = {
  kid_male: <><FaMale /> Criança</>,
  young_male: <><FaMale /> Jovem</>,
  adult_male: <><FaMale />Adulto</>,
  old_male: <><FaMale />Senhor </>,
  kid_female: <> <FaFemale />Criança </>,
  young_female: <><FaFemale />Jovem </>,
  adult_female: <><FaFemale />Adulto </>,
  old_female: <><FaFemale />Senhor </>
}

const DemographicsStyled = styled.div`
  > .c{
    display: flex;
    flex-wrap: wrap;
       justify-content: space-between;
    column-gap: 25px;
  
    >.c_h{
      width: 100%;
    }
    
    @media (min-width: 1450px) {
      ._s_0, 
      ._s_1, 
      ._s_2, 
      ._s_3,
      ._s_4,
      ._s_5{
        width: calc(16.66% - 31px);
      }
  
      ._s_6,
      ._s_7,
      ._s_8,
      ._s_13,
      ._s_14,
      ._s_15{
        width:calc(33.33% - 25px);
      }
      
      ._s_9,
      ._s_10,
      ._s_11,
      ._s_12{
        width: calc(50% - 18px);
      }
    }
  
    @media (min-width: 1099px) and (max-width: 1449px)  {
      ._s_0, 
      ._s_1, 
      ._s_2, 
      ._s_3,
      ._s_4,
      ._s_5,
      ._s_9,
      ._s_10,
      ._s_11,
      ._s_12{
        width:  calc(50% - 12.5px);
      }
      ._s_6,
      ._s_7,
      ._s_8,
      ._s_13,
      ._s_14,
      ._s_15{
        width:calc(33.33% - 17px);
      }
    
    }
    @media (min-width: 700px) and (max-width: 1099px){
      ._s_0, 
      ._s_1, 
      ._s_2, 
      ._s_3,
      ._s_4,
      ._s_5{
        width: calc(50% - 12.5px);
      }
    }
  }
  `

