import { GridRowIdGetter, GridValidRowModel } from "@mui/x-data-grid"
import styled from "styled-components"
import Grid from "../../components/Grid/Grid"
import { GridColsDef } from "../../components/Grid/GridColsDef"
import { GroupText } from "../../components/GroupText/GroupTextRoot"
import WindowComponent from "../../components/WindowComponent/WindowComponent"
import useFetchData from "../../components/useFetchData"
import spinner from '../../images/spinner.gif'
import { dev, std } from "../../std"
import { IEmotions, IGenericsData, MediaC, PropsSection, theme } from "./Sections"


const MediaStyled = styled.div`
  > .c{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 25px;
  
    >.c_h{
      width: 100%;
    }
    ._s_0, 
      ._s_1, 
      ._s_2, 
      ._s_3,
      ._s_4,
      ._s_5{
        width:calc(16% - 20px);
      }
  
  
    @media (max-width: 1190px) {
      ._s_0, 
      ._s_1, 
      ._s_2, 
      ._s_3,
      ._s_4,
      ._s_5{
        width:calc(33.33% - 20px);
      }
      
    }
    @media (max-width: 700px) {
      ._s_0, 
      ._s_1, 
      ._s_2, 
      ._s_3,
      ._s_4,
      ._s_5{
        width:100%;
      }
      
    }
  }
  `

const cols = new GridColsDef({
  default: {
    width: 100,
    align: 'left',
    headerAlign: 'left',
  },
  items: [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      width: 240,
      field: 'name',
      headerName: 'Name',
    },
    {
      field: 'ots',
      headerName: 'OTS',
    },
    {
      field: 'watchers',
      headerName: 'Watchers',
    },
    {
      field: 'dwell_time',
      headerName: 'Dwell time, sec.',
      width: 130,
    },
    {
      field: 'attention_time',
      headerName: 'Attention time, sec.',
      width: 130,
    },
    {
      field: 'last_view',
      headerName: 'Last view',
      width: 250,
    },
  ],
});



const MediaSection = ({ makeAjax, devices_parms_url }: PropsSection) => {
  dev.log("MediaSection")
  
  const RequestPromise1 = useFetchData<IEmotions>("json/emotions.json?"  + devices_parms_url, 'get', {dt_format: "YYYY-MM-DD HH", tzo: "-3" }, makeAjax);
  const RequestPromise2 = useFetchData<IMovies>("json/movies.json?" + devices_parms_url, 'get', undefined, makeAjax);

  
  const loading = RequestPromise1.loading && RequestPromise2.loading
  const error = RequestPromise1.error && RequestPromise2.error 
  const dados = RequestPromise1.data && RequestPromise2.data 

  if (dados) {
    var ensureTopContentAttention: string = "";
    var dataGrid: CMediaSection[] = [];
    var ots_n = RequestPromise1.data?.data?.ots || 0
    var v_n = RequestPromise1.data?.data?.v || 0

    if (RequestPromise1.data?.data?.o) {
      var mapPromise1 = std.groupBy<IGenericsData>(RequestPromise1.data?.data?.o, (no) => no.n)
      var attention_time_ant = 0

      RequestPromise2.data?.used.forEach((no) => {
        var pushDataGrid: IMediaSection = { id: no.i, name: no.n, last_view: no.last_view }
        if (mapPromise1[no.i]) {
          var x = mapPromise1[no.i][0]
          pushDataGrid.ots = x.ots;
          pushDataGrid.attention_time = x.vd;
          pushDataGrid.dwell_time = x.otsd;
          pushDataGrid.watchers = x.v;

          if (x.vd > attention_time_ant) {
            attention_time_ant = x.vd;
            ensureTopContentAttention = pushDataGrid.name
          }
        }
        dataGrid.push(new CMediaSection(pushDataGrid))
      }
      )
      const getRowId: GridRowIdGetter<GridValidRowModel> = (row) => row.id;
      
      MediaC.items[0].Component = <GroupText t_1={ots_n} t_2='persons' />
      MediaC.items[1].Component = <GroupText t_1={v_n} t_2='persons' />
      MediaC.items[2].Component = <GroupText t_1={ensureTopContentAttention} t_2={attention_time_ant} />
      MediaC.items[3].Component = <GroupText t_1={ensureTopContentAttention} t_2={""} />
      MediaC.items[4].Component = <GroupText t_1={(RequestPromise1.data.data.otsd /RequestPromise1.data.data.ots).formatToSeconds() + " segundos" } t_2={"Por Visualização"} />
      MediaC.items[5].Component = <GroupText t_1={(RequestPromise1.data.data.vd /RequestPromise1.data.data.v).formatToSeconds() + " segundos"} t_2={"Por Visualização"} />
      MediaC.items[6].Component =
        <div style={{ marginTop: "25px" }}>
          <Grid themeProps={theme}  getRowId={getRowId}
            columns={cols.getItems()} rows={dataGrid} />
        </div>
    }
  }

  return (
    <MediaStyled>
      <div className='c'>
        {MediaC.items.map((data, idx) =>
          <div className={"c_h _s_" + idx} key={"media_" + idx}>
             <WindowComponent.Root  height={data.Height} >
                <WindowComponent.Header>
                  <WindowComponent.Title >
                      {data.Title}
                  </WindowComponent.Title>
                </WindowComponent.Header>
                <WindowComponent.Content>
                   {loading && <img className="c_img_loading" src={spinner} alt="" />}
                   {error && <img className="c_img_loading" src={spinner} alt="" />}
                   {dados && data.Component}
                </WindowComponent.Content>

              </WindowComponent.Root>
          </div>
        )}
      </div>
    </MediaStyled>
  )
}

export default MediaSection


interface IMediaSection {
  id: number | string;
  name: string;
  ots?: number;
  watchers?: number;
  dwell_time?: number | string;
  attention_time?: number;
  last_view: string;
}

class CMediaSection implements IMediaSection {
  id: number | string;
  name: string;
  ots?: number;
  watchers?: number;
  dwell_time?: number | string;;
  attention_time?: number;
  last_view: string;

  constructor(data: IMediaSection) {
    this.id = data.id;
    this.name = data.name;
    this.ots = data.ots || 0;
    this.watchers = data.watchers || 0;
    this.dwell_time = data.dwell_time || 0;
    this.attention_time = data.attention_time || 0;
    this.last_view = data.last_view;
  }
}

interface IMovies {
  not_used: any[]
  not_used_total: number;
  used: IMoviesUsed[];
  used_total: number;
}

interface IMoviesUsed {
  acl: any[]
  i: number;
  last_view: string;
  n: string;
}


