import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useNavigate, useParams } from 'react-router-dom';
import BoxSelector from '../../components/BoxSelector/BoxSelector';
import ComboBox from '../../components/ComboBox/ComboBox';
import { DateRange, ExampleCustomInput } from '../../components/DatePickerC/DatePickerC';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { Section } from '../../components/Section';
import useFetchData from '../../components/useFetchData';
import { dev } from '../../std';
import DemographicsSection from './DemographicsSection';
import DevicesSection, { IDevices, IDevicesOnAndOff } from './DevicesSection';
import { GeneralSection } from './GeneralSection';
import HomeStyled from './HomeStyled';
import MediaSection from './MediaSection';
import OverTimeSection from './OverTimeSection';
import { parmsDuplicate } from './Sections';

const options = ["General", "Demographics", "Devices", "Over Time", "Media"]

type HomeTypes = {
  Clients: any;
}

type Props = {}



const ensureDevices = (d: IDevices) => {

  var devices: IDevicesOnAndOff[] = [];
  var allDevices = [
    {
      n: 'All Devices'
    }
  ]

  if (d != undefined) {

    dev.log(d.online)
    devices = [...d.offline]
    for (let idx in d.online) {
      let el = d.online[idx]
      devices.push(el)
    }


    for (let idx in devices) {
      var el = devices[idx];
      allDevices.push(el)
    }

  }
  return { devices, allDevices };
}

const Home = ({ Clients }: any) => {
  const { section = '' } = useParams<{ section: Section }>();
  const n = useNavigate();
  useEffect(() => {
    dev.log("componentDidMount")

    return () => {

      dev.log('componentWillUnmount ');
    };
  }, []
  )


  const d = useFetchData<any>("json/devices_load.json")
  //const [section, setSection] = useState("General");
  const selected = (str: string) => {
    n("/Home/" + str)
  };

  const { devices, allDevices } = ensureDevices(d.data)

  dev.log("Home")

  const optionsRender: { [key: string]: boolean } = {
    "General": section === "General",
    "Demographics": section === "Demographics",
    "Devices": section === "Devices",
    "Over Time": section === "Over Time",
    "Media": section === "Media",
  };

  // const [LojasLike, setLojasLike] = useState<ResponseDataP | null>(null);



  const buttonRef = useRef<HTMLButtonElement>(null);
  const currentTime = new Date();
  const tomorrow = new Date(currentTime.getTime() + (24 * 60 * 60 * 1000))
  const thirtyDaysAgo: Date = new Date(currentTime.getTime() - (30 * 24 * 60 * 60 * 1000));
  const range = new DateRange(thirtyDaysAgo, tomorrow);
  const [Select, setSelect] = useState(allDevices[0].n)
  const [dateRange, setDateRange] = useState<DateRange>(range);
  const handleSelect = (value: string) => setSelect(value);
  const datePickerRef = useRef<DatePicker>(null);
  const startDate = dateRange.start;
  const endDate = dateRange.end;
  const [calendarClosed, setCalendarClosed] = useState(false);
  const isGeneral = optionsRender["General"]
  const isDemographics = optionsRender["Demographics"]
  const isDevices = optionsRender["Devices"]
  const isOverTime = optionsRender["Over Time"]
  const isMedia = optionsRender["Media"]

  const handleCalendarClose = () => {
    setCalendarClosed(true);
  };


  const handleClickDiv = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };



  const parms_url = parmsDuplicate(devices, "d", "d")

  return (
    <>
      <div className='containerMain'>
        <Header />
        <HomeStyled >
          <div className='h2'></div>
          <div className='c'>
            <div className='t'>
              PORTAL SEEMETRIX
            </div>
            <div className='t2'>
              <ComboBox field='n' options={allDevices} onSelect={handleSelect} />

              <DatePicker
                ref={datePickerRef}
                locale="pt-BR"
                className="custom-datepicker"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                dateFormat="dd.MM.yy"
                withPortal
                customInput={<ExampleCustomInput ref={buttonRef} value="" onClick={() => { }} />}
                onCalendarClose={handleCalendarClose}
                onChange={(update) => {
                  if (Array.isArray(update)) {
                    const [start, end] = update;
                    if (start != null) {

                      var range = new DateRange(start, end);
                      setDateRange(range);
                    }
                  }

                }}

                portalId="c_calendar_seemetrix"
              />
              <div className='clear'>
                Clear All
              </div>


            </div>
            <BoxSelector options={options} selected={selected}
              flexDirection='row' width='100%'
              justifyContent="center"
              optionChecked={sectionIdx[section]}
            />


            <Section show={isGeneral}>
              <GeneralSection makeAjax={isGeneral} devices_parms_url={parms_url} />
            </Section>
            <Section show={isDemographics}>
              <DemographicsSection makeAjax={isDemographics} devices_parms_url={parms_url} />
            </Section>
            <Section show={isDevices}>
              <DevicesSection makeAjax={isDevices} devices={devices} devices_parms_url={parms_url} />
            </Section>
            <Section show={isOverTime}>
              <OverTimeSection makeAjax={isOverTime} devices_parms_url={parms_url} />
            </Section>
            <Section show={isMedia}>
              <MediaSection makeAjax={isMedia} devices_parms_url={parms_url} />
            </Section>
          </div>
        </HomeStyled>

      </div>
      <Footer />
    </>
  )
}

export default Home;

const sectionIdx: Record<Section, number> = {
  "": -1,
  "General": 0,
  "Demographics": 1,
  "Devices": 2,
  "Over Time": 3,
  "Media": 4,
};
type Section = 'General' | 'Demographics' | 'Devices' | 'Over Time' | 'Media' | '';