import styled from "styled-components";

const HomeStyled = styled.div`
    font-family: "Lato Bold";
    color: rgb(109, 113, 116);
.c_img_loading{
    max-height: 100%;
    max-width: 100%;
    margin: auto;
  }
  >.h2{
    height: 34px;
    background: #212121;
  }  
 >.c {
    margin: 0 25px 50px 25px;
  
    >.t{
     height: 85px;
     font-family: 'Lato Black';
     display: flex;
     align-items: center;
     color: #212121;
     font-size: 27px;
    }

    >.t2{
      height: 70px;
      display: flex;
      align-items: center;
      column-gap: 16px;
              .i2{
                  min-width: 165px;
                  height: 35px;
                  background: rgb(0, 0, 0);
                  color: rgb(255, 255, 255);
                  font-family: "Lato Bold";
                  border-radius: 7px;
                  padding: 0px 15px;
                  font-size: 15px;
                  -webkit-box-pack: center;
                  justify-content: center;
                  cursor: pointer;
      }
      >.clear {
        color: #3c8dbc;
        cursor: pointer;
      }
    }
}
    @media only screen and (max-width: 600px) {
        >.c {


         >.t {
            justify-content: center;
            font-size: 22px;
            
        }
        .c_box_selector {
                max-width: 100%;
            }
    }

   
    @media only screen and (max-width: 480px) {
        >.c {
          margin: 0 15px 50px 15px;
        }   
       
    }
 }

 `

export default HomeStyled 